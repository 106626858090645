@import url(https://fonts.googleapis.com/css?family=Droid+Sans);
@import url(http://weloveiconfonts.com/api/?family=fontawesome);

body {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;
    height: 100%;
    background-color: #fafafa !important;
}

p {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: normal;
}

strong {
    font-weight: bold !important;
}

.container {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 100;
}

.btn {
    font-family: 'Open Sans', sans-serif !important;
}

.cursor-p {
    cursor: pointer;
}

.styles_overlay__CLSq- {
    background-color: rgba(0, 0, 0, 0.4);
}

.styles_modal__gNwvD {
    border-radius: 5px;
}

.styles_modalCenter__L9F2w {
    border-radius: 5px;
}

.styles_closeButton__20ID4 {
    outline: none !important;
}

.fw-600 {
    font-weight: 600 !important;
}

hr {
    width: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

.main-wrapper {
    height: 100%;
}

.text-black {
    color: #000 !important;
}

.text-a {
    color: blue !important;
    text-decoration: underline !important;
}
a.text-white:focus, a.text-white:hover {
    color: #FAFAFA !important;
}

.text-blue {
    color: #008AFF !important;
}

.text-orange {
    color: #fe8260 !important
}

.flex-wrap {
    flex-wrap: wrap !important;
}

a {
    text-decoration: none !important;
    color: #ffffff !important;
}

.navbar-ui-custom a.text-white:focus, a.text-white:hover {
    color: #ffffff !important;
}

.profile-item {
    display: block;
}

/* Sweep To Top */
.hvr-sweep-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;
}

.hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #C2E1FE;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
    color: white;
    border-bottom: 3px solid #008AFF;
}

.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-21 {
    font-size: 21px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

@media (max-width: 800px) {
    .font-size-10 {
        font-size: 9px !important;
    }

    .font-size-11 {
        font-size: 10px !important;
    }

    .font-size-12 {
        font-size: 11px !important;
    }

    .font-size-13 {
        font-size: 12px !important;
    }

    .font-size-14 {
        font-size: 13px !important;
    }
}

.mr-1 {
    margin-right: 1px;
}

.mr-2 {
    margin-right: 2px;
}

.mr-3 {
    margin-right: 3px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-35 {
    margin-right: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-45 {
    margin-right: 45px;
}

.mr-50 {
    margin-right: 50px;
}

.ml-1 {
    margin-left: 1px;
}

.ml-2 {
    margin-left: 2px;
}

.ml-3 {
    margin-left: 3px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-35 {
    margin-left: 35px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-45 {
    margin-left: 45px;
}

.ml-50 {
    margin-left: 50px;
}

/* Nav Bar ui */
.navbar-ui-custom {
    background-color: #008AFF !important;
    padding: .25rem !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.text-white {
    color: #ffffff !important;
}

.navbar-ui-item-active {
    background-color: #2D9EFF !important;
    border-radius: 4px;
    transition: 0.5s;
}

/*.navbar-ui-custom .nav-link.active {*/
/*background-color: #30A0FF !important;*/
/*border-radius: 5px;*/
/*}*/
.navbar-item-right:focus, .navbar-item-right:active {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.navbar-item-right {
    background-color: white !important;
    border-radius: 5px;
    transition: 0.2s;
}

.navbar-item-right a {
    color: #008AFF !important;
}
.navbar-item-right-permium {
    background-color: white !important;
    border-radius: 5px;
    transition: 0.2s;
}

.navbar-item-right-permium a {
    color: orangered !important;
}





/* circular styles*/
.CircularProgressbar {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
    width: 5rem;
    height: 10%;
  }
  
  .CircularProgressbar .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
  }
  
  .CircularProgressbar .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }
  
  .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6;
  }
  
  /*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: #3e98c7;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }





.cent{
    display: block;
    margin: auto;
}

.navbar-item-icon {
    font-size: 23px;
}

.navbar-brand-ui-icon {
    margin: 0 !important;
    padding: 0 !important;
}

.navbar-brand-ui-icon-img {
    width: 140px;
    margin-left: 15px;
}

.nav-item-ui-custom a {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.nav-profile-avatar {
    position: relative;
}

.nav-profile-avatar div.avater-img {
    width: 47px;
    height: 47px;
    background-color: #dedede;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
}

.nav-logo-avatar {
    position: relative;
}

.nav-logo-avatar div.logo-img {
    width: 120px;
    height: 47px;
    background-color: #dedede;
    /* border-radius: 100%; */
    background-size: cover;
    background-position: center;
}

@media (min-width: 768px) {
    .hide-in-768 {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .hide-under-768 {
        display: none !important;
    }

    .navbar-brand-ui-icon-img {
        object-fit: cover;
        object-position: 0px 0px;
        width: 50px;
        height: 50px;
    }

    .navbar-ui-custom {
        display: flex;
        justify-content: space-between;
    }

}

.avatar-green-spot {
    width: 12px;
    height: 12px;
    background-color: #00D983;
    position: absolute;
    right: 3px;
    bottom: -2px;
    border-radius: 50px;
    border: 1px solid white;

}

.navbar-toggeler-icon-custom {
    border-width: 0 !important;
    padding: 5px;
}

.navbar-toggeler-icon-custom svg {
    font-size: 26px;
}

.fade {
    height: 100vh;
    padding: calc(15% + 1.5em) 5% 5%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

/* Page */

.page {
    height: 100vh;
    padding: calc(15% + 1.5em) 5% 5%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transition: box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
    transition: transform 1s ease-in-out, box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.page-enter {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}

.page-enter-active {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}

.page-exit {
    box-shadow: 0 0 5em 0 rgba(0, 0, 0, .5) inset;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}

.page--prev.page-enter {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}

.page--prev.page-enter-active {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}

.page--prev.page-exit {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}

.page-exit .page__inner {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.page h1 {
    margin-top: 0;
}

/* Sub Menu */

@media (max-width: 768px) {

    .submenu-items {
        margin-left: 0 !important;
        justify-content: space-around;
    }
}

.submenu-wrapper {
    background-color: white !important;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.5);
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
}

.submenu-items {
    display: flex;
    margin-left: 164px;
}

.submenu-item {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.submenu-item {
    color: #0085FE !important;
}

.submenu-item-active {
    background-color: #C2E1FE;
    color: #0085FE !important;
    border-bottom: 3px solid #008AFF;
}

/* Dropdown */

.dropdown-menu-ui-custom button {
    color: #494949 !important;
}

.dropdown-menu-ui-custom-notif {
    min-width: 22rem !important;
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

/* Decisions */
.decisions-header {
    border-bottom: 1px solid #e9e9e9;
}

.people-header {
    border-bottom: 1px solid #e9e9e9;
}

@media (max-width: 576px) {
    .decision-wrapper {
        flex-flow: column;
        text-align: center;
        overflow-x: scroll;
    }

    .people-wrapper {
        flex-flow: column;
        text-align: center;
        overflow-x: scroll;
    }

    .new-decision-button-wrapper {
        margin-top: 20px;
    }

    .new-decision-button {
        padding: .5rem .5rem !important;
        line-height: 1.5 !important;
        border-radius: .2rem !important;
        font-size: 12px !important;
    }
}

.decisions-header-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #686868;
    margin-top: 10px;
    margin-left: 20px;
}

.people-header-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #686868;
    margin-top: 10px;
    margin-left: 20px;
}

.decision-icon {
    color: #686868;
}

.people-icon {
    color: #686868;
}

.new-decision-button {
    color: white !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600 !important;
    font-size: 12px !important;
    /*background-color: #FF8260 !important;*/
}

.decision-wrapper {
    background-color: white;
}

.people-wrapper {
    background-color: white;
}

.decision-table {
    margin-top: 20px;
}

.people-table {
    margin-top: 20px;
}

.decision-table table tr th {
    font-family: 'Open Sans', sans-serif !important;
    color: #b2b2b2;
    font-weight: 400;
    border: none !important;
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    margin-bottom: 20px;
}

.people-table table tr th {
    font-family: 'Open Sans', sans-serif !important;
    color: #b2b2b2;
    font-weight: 400;
    border: none !important;
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    margin-bottom: 20px;
}

.decision-table table tbody tr td {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
    border: none !important;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: middle;
}

.people-table table tbody tr td {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
    border: none !important;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: middle;
}

.decision-table table tbody tr:hover {
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.2);
    transition: 0.5s;
}

.people-table table tbody tr:hover {
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.2);
    transition: 0.5s;
}

.decision-table {
    font-family: 'Open Sans', sans-serif !important;
}

.people-table {
    font-family: 'Open Sans', sans-serif !important;
}

/* spinner-loader */
.spinner-loader {
    display: flex;
    height: 70%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* Btn Success*/

.btn-success-custom {
    color: #fff !important;
    background-color: #fe8260 !important;
    border-color: #fe6e47 !important
}

.btn-success-custom:focus, .btn-success-custom.focus {
    color: #fff !important;
    background-color: #fe5a2d !important;
    /*border-color: #c42b01 !important;*/
    box-shadow: 0 0 0 3px rgba(254, 90, 45, .15), 0 3px 15px rgba(254, 90, 45, .2), 0 2px 5px rgba(0, 0, 0, .1) !important;
}

.btn-success-custom:hover {
    color: #fff !important;
    background-color: #fe5a2d !important;
    border-color: #fd3e0a !important
}

.btn-success-custom:active, .btn-success-custom.active, .open > .dropdown-toggle.btn-success-custom {
    color: #fff !important;
    background-color: #fe5a2d !important;
    background-image: none !important;
    border-color: #fd3e0a !important
}

.btn-success-custom:active:hover, .btn-success-custom.active:hover, .open > .dropdown-toggle.btn-success-custom:hover, .btn-success-custom:active:focus, .btn-success-custom.active:focus, .open > .dropdown-toggle.btn-success-custom:focus, .btn-success-custom:active.focus, .btn-success-custom.active.focus, .open > .dropdown-toggle.btn-success-custom.focus {
    color: #fff !important;
    background-color: #fd3e0a !important;
    border-color: #c42b01 !important
}

.btn-success-custom.disabled:hover, .btn-success-custom[disabled]:hover, fieldset[disabled] .btn-success-custom:hover, .btn-success-custom.disabled:focus, .btn-success-custom[disabled]:focus, fieldset[disabled] .btn-success-custom:focus, .btn-success-custom.disabled.focus, .btn-success-custom[disabled].focus, fieldset[disabled] .btn-success-custom.focus {
    background-color: #fe8260 !important;
    border-color: #fe6e47 !important
}

.btn-success-custom .badge {
    color: #fe8260 !important;
    background-color: #fff !important
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-top: 6px;
    margin-right: 7px;
}

/* Make Decision*/

@media (max-width: 768px) {
    .ui-container {
        width: 94% !important;
    }

    .colored-bar-step {
        width: 70px !important;
    }
}

@media (max-width: 572px) {
    .colored-bar-step {
        width: 50px !important;
        border-radius: 0 !important;
    }

    .ui-container {
        width: 100% !important;
        border-radius: 0 !important;
    }
}

.ui-container {
    margin-top: 100px;
    background-color: white !important;
    width: 78%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /*box-shadow: 0px 0px 30px rgba(100, 100, 100, 0.2);*/
    border-radius: 8px;
    border: 1px solid rgba(100, 100, 100, 0.1);
}

.colored-bar-step {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #008AFF;
    width: 90px;
    justify-content: space-between;
    align-items: center;
}

.calender-btn:hover span {
    cursor: pointer !important;
    color: #008AFF;
}

.fade-ui-custom {
    height: auto !important;
    width: auto !important;
    padding: 0 !important;
    position: absolute;
    top: 78px;
    -webkit-overflow-scrolling: touch;
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
    z-index: 9999;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(100, 100, 100, 0.2);
}

.form-ui-icon {
    font-size: 50px;
    transition: 0.1s ease-out;
}

.form-ui-icon:hover {
    cursor: pointer !important;
}

.ui-dot {
    width: 7px;
    height: 7px;
    background-color: white;
    border-radius: 50%;
    transition: width 0.2s, height 0.2s;
}

.ui-dot-active {
    width: 15px;
    height: 15px;
}

.drop-ui-container {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Form */
.form-ui-header {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.form-ui-header h3 {
    color: #008AFF;
}

.form-ui-label {
    font-weight: 300;
    transition: 0.2s;
    cursor: text !important;
}

.select-attribute-wrapper {

}

.select-attribute-body {
    margin: 15px 0;
}

.select-attribute-item {
    padding: 15px;
    border-left: 4px solid #fe8260;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 5px rgba(100, 100, 100, 0.2);
}
.select-attribute-item2 {
    padding: 15px;
    border-left: 4px solid #fff;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 5px rgba(100, 100, 100, 0.2);
}

.select-attribute-tag:hover {
    cursor: default !important;
}

.select-attribute-tag {
    padding: 8px 7px !important;
    padding-left: 15px !important;
    font-size: 16px !important;
    background-color: #fe8260 !important;
}

.col-form-label-ui {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px !important;
}

.col-form-label-ui svg {
    font-size: 22px !important;
    vertical-align: -4px;
}

@media (max-width: 1000px) {
    .modal {
        position: fixed;
        top: 100 !important;
    }
}

.modal {
    position: absolute !important;
    top: -180px;
    overflow: auto;

}

.ql-editor {
    height: 100px !important;
    overflow: auto;
}

/* File picker drop zone*/
.dropzone {
    font-weight: 100;
}

/* Poll details wrapper */
.poll-wrapper {
    margin-top: 70px;
    margin-bottom: 30px;
    padding-top: 10px;
}

.poll-data-wrapper {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.poll-sppiner-wrapper {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.poll-data-header-title {
    font-weight: 300;
    color: #008AFF;
}

.poll-data-header-creator {
    color: #008AFF !important;
}

.status-custom-badge {
    display: inline;
    color: #000;
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.status-custom-badge:hover {
    cursor: pointer;
}

.problem-details {
    background-color: rgba(0, 0, 0, 0.02);
    padding: 19px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.attachment-ui {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.attachment-ui-icon {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.attachment-ui-icon svg {
    font-size: 40px !important;
}

.attachment-placeholder {
    font-weight: 100;

}

.attribute-badge {
    
    padding: 5px 15px;
    margin: 5px;
    background-color: #00D983;
    border-radius: 50px;
    color: white;
    font-weight: 600 !important;
    font-size: 15px;
}

.alternative-badge {
    /* display: flex;
    justify-content: space-around; */
    padding:6px 0px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.5s;
}

.alternative-badge_size {
    display: flex;
    justify-content: space-around;

    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.5s;
}

.alternative-badge:hover {
    border: 1px solid #008AFF;
    color: #008AFF;
}

.alter-description-area p {
    margin: 0 !important;
    padding: 0 !important;
}

/* editor */

.editor .ql-editor {
    min-height: 200px !important;
}

/* Modal */
.modal-responsive {
    width: 500px;
}

@media (max-width: 580px) {
    .modal-responsive {
        width: 300px;
    }
}

/* Checkbox Change Color */
.custom-toggle .custom-control-input:checked ~ .custom-control-label::before {
    background: #007bff !important;
    border-color: #007bff !important;
}

.profile-wrapper {
    margin-top: 70px;
    margin-bottom: 30px;
}

.profile-data-wrapper {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0 !important;
}

.container {
    /*position: relative;*/
}

@media (max-width: 768px) {
    .profile-header-pic {
        width: 100px !important;
        height: 100px !important;
    }
}

@media (max-width: 568px) {
    .profile-header-pic {
        width: 70px !important;
        height: 70px !important;
    }
}

.profile-header-pic {
    /*position: absolute;*/
    width: 150px;
    height: 150px;
    background-color: #dedede;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    z-index: 99;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.user-badge {
    display: inline;
    background-color: #f6f6f6;
    border: 1px solid #dcdcdc;
    border-radius: 7px;
    padding: 0 2px;
}

.user-badge-wrapper:first-child {
    margin: 0 !important;
}

.slider-item {
    /*background-color: #008AFF;*/
    color: white;
    border-radius: 10px;
    padding: 15px 0;
}

.slider-item h6 {
    color: #008AFF;
}

.slider-item h5 {
    color: #008AFF;
}

.pagination nav ul li a {
    color: #008AFF !important;
    font-weight: 300 !important;
    cursor: pointer;
}

.pagination nav ul li a.page-link:focus {
    box-shadow: none !important;
}

/* auto complete style */

.react-autosuggest__input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.react-autosuggest__input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #007bff;
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, .1), 0 0.156rem 0.125rem rgba(0, 0, 0, .06);
}

.react-autosuggest__input:hover {
    border-color: #8fa4b8;
}

.react-autosuggest__input {
    height: auto;
    padding: .5rem 1rem;
    font-size: .95rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #becad6;
    font-weight: 300;
    will-change: border-color, box-shadow;
    border-radius: .375rem;
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(.27, .01, .38, 1.06), border 250ms cubic-bezier(.27, .01, .38, 1.06);
    overflow: visible;
    display: block;
    width: 100%;
    background-clip: padding-box;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__suggestions-container--open {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #292b2c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

.react-auto-suggest-item {
    display: block;
    width: 100%;
    padding: 3px 1.5rem;
    clear: both;
    font-weight: 400;
    color: #292b2c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
}

.react-auto-suggest-item:hover {
    cursor: pointer;
    background-color: #008AFF;
    color: white !important;
}

.react-auto-suggest-item:hover .text-muted {
    color: white !important;
}

.react-autosuggest__suggestions-list {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
}

.show-message {
    border-right: 1px solid #00D983;
}

/* Slider handle */
.noUi-handle {
    background-color: #008AFF !important;
}

/* Notification ui*/
.notif-icon {
    position: relative;
}

.notif-icon:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
}

.notif-icon-parent:after {
    content: none !important;
}

.notif-icon-badge {
    position: absolute;
    background-color: #fe8260 !important;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 5px;
}

.drop-down-notification {
    width: 300px;
}

.notif-user-name {
    font-weight: 600;
}

.dropdown-item-custom {
    cursor: default !important;
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.notif-user-tag {
    color: #008AFF !important;
}

.notif-ui-profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #8fa4b8;
    border-color: #686868;
}

.inv-modal {
    width: 500px;
}

/* Navbar Icon */
.decision-icon-ui {
    background-image: url(/static/media/technology.c6d0521c.png);
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
}

.decision-icon-ui-title {
    background-image: url(/static/media/technology_black.02068c25.png);
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}

.attribute-icon-ui {
    background-image: url(/static/media/artificial-intelligence.6994b3b0.png);
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
}

.attribute-icon-ui-title {
    background-image: url(/static/media/artificial-intelligence_black.ee4d41b1.png);
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}

.profile-icon-ui {
    background-image: url(/static/media/marketing.e591f1cc.png);
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
}

.profile-icon-ui-title {
    background-image: url(/static/media/marketing_black.d13f282d.png);
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}

.chart-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.bg-white {
    background-color: white !important;
}

.chart-left-side {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    justify-content: space-between;
}

.media-text-description {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: normal;
    font-size: 15px;
}

.media-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #a4bcd4;
    border: 1px solid #8b9eb0;
}

.user-comment {
    color: #008AFF !important;
    font-weight: 600 !important;
    cursor: pointer;
    font-size: 20px;
}

@media (max-width: 768px) {
    .decision-result {
        text-align: center !important;
        font-size: 28px;
    }
}

.sidebar-item {
    transition: all 0.5s;
}

.sidebar-item-active {
    background-color: #eeeeee;
}

.sidebar-item:hover {
    background-color: #eeeeee;
}

.profile-image {
    width: 80px;
    height: 80px;
    background-color: #686868;
    border-radius: 50%;
}

input[type="file"] {
    display: none;
}

@media (max-width: 515px) {
    .profile-image-container {
        width: 300px !important;
    }
}

.profile-image-container {
    width: 450px;
}

/* notification style */
.notification-bar {
    font-family: 'Open Sans', sans-serif !important;
    left: 44% !important;
    top: -100% !important;
    bottom: 160% !important;
    transition: all 0.3s cubic-bezier(0.89, 0.01, 0.5, 1.1) 0s;
}

.notification-bar-active {
    top: 2% !important;
    bottom: 55.5rem !important;
}

.Toaster__message-wrapper {
    -webkit-transform: translateY(0%) scale(1) !important;
            transform: translateY(0%) scale(1) !important;
}

.react-auto-suggest-item-icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
}

.people-icon {
    vertical-align: -5px;
    margin-right: 8px;
    font-size: 20px;
    color: white;
}

.Info-graphic {
    background-image: url(/static/media/MeriTa_info.c712f916.png);
    background-position: center;
    background-repeat: no-repeat;
    height: 2000px;
}
@media screen and (max-width:760px){
    .Info-graphic {
        background-image: url(/static/media/1.a17c1c25.png);
        background-position: center;
        background-repeat: no-repeat;
        height: 1200px;
        padding-top: 10px !important;
        padding-bottom: 20px !important;
    }
   
}
.contact-us-email {
    transition: ease-out 0.3s;
}

.contact-us-email:hover {
    color: #008AFF !important;
}


.table_container {
    overflow-x: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    padding: 10px;
    margin: 10px;
  }

  .noUi-target {

    margin: 1.7rem 0 1.7rem 1.3rem !important
    
  }
  .red{
      color:red !important;
  }.black{
      color:black !important;
  }

  .orange{
    color:orangered !important;
    text-decoration: underline !important;
}
.orange_text{
    color:orangered !important;
}

.blue_text{
    color:blue !important;
}
.deco{
    
    text-decoration: underline !important;
}

.react-autosuggest__suggestions-list
{
  max-height: 200px;
  overflow-y: auto;
}
/* .modal-dialog{
    margin: -1.125rem auto !important;
} */



@media (min-width:576px) {
    .modal-dialog-welcome {
        margin: 1.75rem auto !important
    }
}

@media (min-width:992px) {
    .modal-dialog-welcome {
        margin: -2.25rem auto !important
    }
}


/*
Inspired by the dribble shot http://dribbble.com/shots/1285240-Freebie-Flat-Pricing-Table?list=tags&tag=pricing_table
*/

/*--------- Font ------------*/
/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
/*------ utiltity classes -----*/
.fl{ float:left; }
.fr{ float: right; }
/*its also known as clearfix*/
.group:before,
.group:after {
    content: "";
    display: table;
} 
.group:after {
    clear: both;
}
.group {
    zoom: 1;  /*For IE 6/7 (trigger hasLayout) */
}
/* C2E1FE */
.pricing {
    background: #a4bcd4;
    font-family: 'Droid Sans', sans-serif;
    line-height: 1;
    font-size: 16px;    
    margin-top: 10% !important;
}
.wrapper {
    
}

@media (max-width:600px) {
    .block {
        width: 100% !important;    
        margin: 50px 0px !important;

        overflow: hidden;
        border-radius: 5px;  
    }
}

@media (min-width:601px) {
    .block {
        width: 30% !important;    
        margin: 50px 10px !important;

        overflow: hidden;
        border-radius: 5px;  
    }
}
.pricing-table {
    width: 80%;
    margin: 50px auto 0;
    text-align: center;
    padding: 10px;
    padding-right: 0;
}
.pricing-table .heading{
    color: #9C9E9F;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-bottom: 4rem;
}
.block{
    width: 30%;    
    margin: 0 15px;
    overflow: hidden;
    border-radius: 5px;    
/*    border: 1px solid red;*/
}
/*Shared properties*/
.title,.pt-footer{
    color: #FEFEFE;
    text-transform: capitalize;
    line-height: 2.5;
    position: relative;
}
.content{
    position: relative;
    color: #FEFEFE;
    padding: 20px 0 10px 0;
}
/*arrow creation*/
.content:after, .content:before,.pt-footer:before,.pt-footer:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.pt-footer:after,.pt-footer:before{
    top:0;
}
.content:after,.pt-footer:after {
	border-color: rgba(136, 183, 213, 0);	
	border-width: 5px;
	margin-left: -5px;
}
/*/arrow creation*/
.price{
    position: relative;
    display: inline-block;
    margin-bottom: 0.625rem;
}
.price span{    
    font-size: 5rem;
    letter-spacing: 8px;
    font-weight: bold;        
}
.price sup{
    font-size: 1.5rem;    
    position: absolute;    
    top: 12px;
    left: -12px;
}
.hint{
    font-style: italic;
    font-size: 0.9rem;
}
.features{
    list-style-type: none;    
    background: #FFFFFF;
    text-align: left;
    color: #9C9C9C;
    padding:30px 8%;
    font-size: 0.9rem;
}
.features li{
    padding:15px 0;
    width: 100%;
}
.features li span{
   padding-right: 0.4rem; 
}
.pt-footer{
    font-size: 0.95rem;
    text-transform: capitalize;
}
/*PERSONAL*/
.personal .title{        
    background:darkcyan;    
}
.personal .content,.personal .pt-footer{
    background: darkcyan;
}
.personal .content:after{	
	border-top-color: #82DACA;	
}
.personal .pt-footer:after{
    border-top-color: #FFFFFF;
}
/*PROFESSIONAL*/
.professional .title{
    background: #3EC6E0;
}
.professional .content,.professional .pt-footer{
    background: #53CFE9;
}
.professional .content:after{	
	border-top-color: #53CFE9;	
}
.professional .pt-footer:after{
    border-top-color: #FFFFFF;
}
/*BUSINESS*/
.business .title{
    background: #E3536C;
}
.business .content,.business .pt-footer{
    background: #E3536C;
}
.business .content:after{	
	border-top-color: #E3536C;	
}
.business .pt-footer:after {	
	border-top-color: #FFFFFF;	
}
.nav {
    margin-bottom: 15px;
    text-align: center;
}

.dot {
    color: #686868;
    cursor: pointer;
    font-size: 36px;
    line-height: 1;
    margin: 0 15px;
    opacity: .4;
    text-shadow: none;
    transition: opacity 1s ease,
    text-shadow 1s ease;
    will-change: opacity, text-shadow;
}

.active {
    color: #007bff !important;
    opacity: 1;
    text-shadow: 0 0px 8px;
}
.animated {
    -webkit-animation-duration: .8192s;
            animation-duration: .8192s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

/** intro */
@-webkit-keyframes intro {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(0, 0, -50px);
                transform: perspective(500px) translate3d(0, 0, -50px);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
@keyframes intro {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(0, 0, -50px);
                transform: perspective(500px) translate3d(0, 0, -50px);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

.intro {
    -webkit-animation: intro 1s ease-out;
            animation: intro 1s ease-out;
}

/** enterRight */
@-webkit-keyframes enterRight {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(20%, 0, 0);
                transform: perspective(500px) translate3d(20%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
@keyframes enterRight {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(20%, 0, 0);
                transform: perspective(500px) translate3d(20%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

.enterRight {
    -webkit-animation-name: enterRight;
            animation-name: enterRight;
}

/** enterLeft */
@-webkit-keyframes enterLeft {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(-20%, 0, 0);
                transform: perspective(500px) translate3d(-20%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}
@keyframes enterLeft {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(-20%, 0, 0);
                transform: perspective(500px) translate3d(-20%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

.enterLeft {
    -webkit-animation-name: enterLeft;
            animation-name: enterLeft;
}

/** exitRight */
@-webkit-keyframes exitRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(100%, 0, -100px);
                transform: perspective(500px) translate3d(100%, 0, -100px);
    }
}
@keyframes exitRight {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(100%, 0, -100px);
                transform: perspective(500px) translate3d(100%, 0, -100px);
    }
}

.exitRight {
    -webkit-animation-name: exitRight;
            animation-name: exitRight;
}

/** exitLeft */
@-webkit-keyframes exitLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(-100%, 0, -100px);
                transform: perspective(500px) translate3d(-100%, 0, -100px);
    }
}
@keyframes exitLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(-100%, 0, -100px);
                transform: perspective(500px) translate3d(-100%, 0, -100px);
    }
}

.exitLeft {
    -webkit-animation-name: exitLeft;
            animation-name: exitLeft;
}
